import React from "react"
import { graphql } from "gatsby"
import LoadMore from "../components/LoadMore"
import DirectoryMenu from "../components/DirectoryMenu"
import Seo from "../components/Seo"
import Heading from "../components/Heading"

export default function ListingsIndex({ data }) {
  const page = data && data.page
  const posts = data && data.posts

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
      />
      <div className="flex flex-col justify-stretch w-full">
        <section className="wrapper order-last lg:order-first">
          <div className="py-4">
            <Heading text="Directory" />
            <DirectoryMenu cols={8} />
          </div>
        </section>
        <section className="wrapper my-4">
          <Heading text={page.title} />
          <LoadMore posts={posts.nodes} />
        </section>
      </div>
    </>
  )
}

export const query = graphql`
  query listingsIndexQuery {
    page: sanityListingsIndex {
      _id
      title
      seo {
        ...seoFields
      }
    }
    posts: allSanityListings {
      nodes {
        title
        _id
        _type
        slug {
          current
        }
        content {
          subHeading
          featureImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
